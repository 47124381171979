import Col from 'reacthalfmoon/dist/components/Col';
import Row from 'reacthalfmoon/dist/components/Row';
import { utils } from 'ethers';
import ProfitLossInlineValue from '../ProfitLossInlineValue';
import SmallButton from '../SmallButton';
import Constants from '../../constants/Constants';
import { ReactSVG } from 'react-svg';
import getExchangeUrls from '../../util/getExchangeUrls';
import getScanInfo from '../../util/getScanInfo';
import getChartInfo from '../../util/getChartInfo';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { useState } from 'react';
import Container from 'reacthalfmoon/dist/components/Container';
import toFormattedNumber from '../../util/toFormattedNumber';
import './SinglePortfolioRow.css';
import BlockButton from '../BlockButton';

function SinglePortfolioRow({ token, nativeTokenName }) {
    const [isOpen, setIsOpen] = useState(false);
    const iconStyle = {width: '1em', height: '1em'};

    const {buyUrl, sellUrl} = getExchangeUrls(token.address, token.exchange);
    const {scanLogo, scanUrl} = getScanInfo(token.address, token.network, iconStyle);
    const {chartLogo, chartUrl} = getChartInfo(token.address, token.pairAddress, token.network, iconStyle);

    function toggleIsOpen() {
        setIsOpen(!isOpen);
    }

    function getChevronIcon() {
        return isOpen ? faChevronUp : faChevronDown;
    }

    return (
        <div className="border-bottom bg-light-lm bg-dark-dm">
            <div onClick={toggleIsOpen} className="d-lg-none overflow-hidden">
                <Row>
                    <Col size={6} className="p-10">{token.symbol}</Col>
                    <Col size={6} className="p-10 text-right d-flex align-items-center justify-content-stretch">
                        <div className="d-flex align-items-center justify-content-between flex-grow-1">
                            <span>$</span>
                            <ProfitLossInlineValue value={token.usdValue} decimalsToShow={2} containerSize={18} iconSize="1.2rem" />
                        </div>
                        <FontAwesomeIcon icon={getChevronIcon()} className="flex-grow-0 ml-15" />
                    </Col>
                </Row>
                <Row className={"px-10 spr-expanded-panel" + (isOpen ? " open" : "")}>
                    <Col size={12} className="mb-10 pt-0">
                        <div className="d-flex align-items-center justify-content-between">
                            <div>Name</div>
                            <div className="text-right">{token.name}</div>
                        </div>
                        <div className="d-flex align-items-center justify-content-between">
                            <div>Amount</div>
                            <div className="text-right text-monospaced">{toFormattedNumber(token.balance, 4)}</div>
                        </div>
                        <div className="d-flex align-items-center justify-content-between">
                            <div>BNB Value</div>
                            <div className="text-right text-monospaced">{toFormattedNumber(token.nativeCoinValue, 4)}</div>
                        </div>
                    </Col>
                    <Col size={12}>
                        <Row className="mb-5">
                            <Col size={6} className="pr-5">
                                <BlockButton bgClass="bg-success" textClass="text-white" caption="Buy" url={buyUrl} />
                            </Col>
                            <Col size={6} className="pl-5">
                                <BlockButton bgClass="bg-danger" textClass="text-white" caption="Sell" url={sellUrl} />
                            </Col>
                        </Row>
                        <Row className="mb-10">
                            <Col size={6} className="pr-5">
                                <BlockButton bgClass="bg-primary" textClass="text-white" icon={scanLogo} url={scanUrl} />
                            </Col>
                            <Col size={6} className="pl-5">
                                <BlockButton bgClass="bg-primary" textClass="text-white" icon={chartLogo} url={chartUrl} />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
            <Row className="d-none d-lg-flex">
                <Col size={3} className="p-10">{token.name}</Col>
                <Col size={3} className="p-10 text-right">
                    <ProfitLossInlineValue value={token.balance} decimalsToShow={4}  />
                </Col>
                <Col size={2} className="p-10 text-right">
                    <ProfitLossInlineValue value={token.nativeCoinValue} decimalsToShow={4} />
                </Col>
                <Col size={2} className="p-10 text-right">
                    <ProfitLossInlineValue value={token.usdValue} decimalsToShow={2} />
                </Col>
                <Col size={2} className="p-10 d-flex align-items-center">
                    <SmallButton bgClass="bg-success" textClass="text-white" caption="B" url={buyUrl} />
                    <SmallButton bgClass="bg-danger" textClass="text-white" caption="S" url={sellUrl} />
                    <SmallButton bgClass="bg-primary" textClass="text-white" icon={scanLogo} url={scanUrl} />
                    <SmallButton bgClass="bg-primary" textClass="text-white" icon={chartLogo} url={chartUrl} />
                </Col>
            </Row>
        </div>
    )
}

export default SinglePortfolioRow;