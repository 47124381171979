import React, { Suspense, useEffect, useState } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import ContentWrapper from 'reacthalfmoon/dist/components/ContentWrapper';
import PageWrapper from 'reacthalfmoon/dist/components/PageWrapper';
import Header from './components/header/Header';
import AppSidebar from './components/sidebar/AppSidebar';
import { AppRoutes } from './constants/AppRoutes';
import Page404 from './pages/Page404';
import './App.css';
import { useDispatch, useSelector } from 'react-redux';
import { getConnectedWallet, selectIsConnected } from './redux/slices/web3Slice';
import CookieBot from 'react-cookiebot';

function App() {
    const isWalletConnected = useSelector(selectIsConnected);
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getConnectedWallet());
    }, []);

    function toggleSidebar() {
        setIsSidebarOpen(!isSidebarOpen);
    }

    return (
        <div className="App">
            <CookieBot domainGroupId="a627ecc4-6226-4b65-bf6f-d88bfd1bfac4" />
            <Router>
                <PageWrapper withNavbar withSidebar sidebarType="overlayed-sm-and-down" isSidebarOpen={isSidebarOpen} toggle={toggleSidebar}>
                    <div className="sidebar-overlay" onClick={() => setIsSidebarOpen(false)}></div>
                    <Header onToggleClick={toggleSidebar} />
                    <AppSidebar toggleSidebar={toggleSidebar} />
                    <ContentWrapper className="crylio-content-wrapper">
                        <Suspense fallback={<div>Loading...</div>}>
                            <Switch>
                                {AppRoutes.map((route, i) => {
                                    if ((route.requireAuth && !isWalletConnected) || !route.component) {
                                        return null;
                                    }
                                    return (<Route
                                                key={i}
                                                exact={true}
                                                path={`${route.path}`}
                                                render={(props) => <route.component {...props} />}
                                            />
                                    )
                                })}
                                <Route component={Page404} />
                            </Switch>
                        </Suspense>
                    </ContentWrapper>
                </PageWrapper>
            </Router>
        </div>
    );
}

export default App;
