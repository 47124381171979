import { useEffect, useState } from "react";
import ProfitLossIcon from "./ProfitLossIcon";

export default function ProfitLossCard({ caption, value }) {
    const [prevValue, setPrevValue] = useState(0);
    const [profitStatus, setProfitStatus] = useState(0);

    useEffect(() => {
        if (prevValue != 0) {
            if (value > prevValue) {
                setProfitStatus(1);
            } else if (value < prevValue) {
                setProfitStatus(-1);
            }
        }
        setPrevValue(value);
    }, [value]);

    return (
        <div className="border-transparent rounded bg-gray-lm bg-very-dark-dm p-10 d-flex align-items-center">
            <div className="flex-grow-0 mr-10">
                <ProfitLossIcon profitLossStatus={profitStatus} />
            </div>
            <div>
                <div className="m-0 p-0 text-uppercase font-size-10">{caption}</div>
                <div className="m-0 p-0 font-size-18 font-weight-bold">{value}</div>
            </div>
        </div>
    );
}