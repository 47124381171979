import { ReactSVG } from "react-svg";
import Constants from "../constants/Constants";

export default function getScanInfo(tokenAddress, networkType, iconStyle) {
    let scanLogo;
    let scanUrl;

    switch (networkType) {
        case Constants.NetworkType.Eth:
            scanLogo = <ReactSVG src="/etherscan-logo.svg" style={iconStyle} wrapper='svg' />;
            scanUrl = 'https://etherscan.io/token/' + tokenAddress;
            break;
        case Constants.NetworkType.BSC:
            scanLogo = <ReactSVG src="/bscscan-logo.svg" style={iconStyle} wrapper='svg' />;
            scanUrl = 'https://bscscan.com/token/' + tokenAddress;
            break;
        default:
            scanLogo = null;
            break;
    }

    return {scanLogo, scanUrl};
}