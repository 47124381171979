class LocalStorage {
    static LoadItem(key) {
        if (!key) return null;
        try {
            var item = localStorage.getItem(key)
            return item ? JSON.parse(item) : null;
        } catch (err) {
            return null;
        }
    }

    static SaveItem(key, value) {
        if (!key) return null;
        try {
          localStorage.setItem(key, value);
        } catch (err) {
        }
    }
}

export default LocalStorage;