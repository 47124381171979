import Col from 'reacthalfmoon/dist/components/Col';
import Row from 'reacthalfmoon/dist/components/Row';
import { utils } from 'ethers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEthereum } from '@fortawesome/free-brands-svg-icons';
import { ReactSVG } from 'react-svg';
import Constants from '../../constants/Constants';
import ProfitLossInlineValue from '../ProfitLossInlineValue';
import SmallButton from '../SmallButton';
import getExchangeUrls from '../../util/getExchangeUrls';
import getScanInfo from '../../util/getScanInfo';
import getChartInfo from '../../util/getChartInfo';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { useState } from 'react';
import toFormattedNumber from '../../util/toFormattedNumber';
import BlockButton from '../BlockButton';
import './CombinedPortfolioRow.css';

function CombinedPortfolioRow({ token }) {
    const [isOpen, setIsOpen] = useState(false);
    const iconStyle = {width: '1em', height: '1em'};

    const {buyUrl, sellUrl} = getExchangeUrls(token.address, token.exchange);
    const {scanLogo, scanUrl} = getScanInfo(token.address, token.network, iconStyle);
    const {chartLogo, chartUrl} = getChartInfo(token.address, token.pairAddress, token.network, iconStyle);

    function getNetworkIcon() {
        const iconStyle = {height: '2rem', width: '2rem'};
        switch (token.network) {
            case Constants.NetworkType.Eth:
                return <FontAwesomeIcon icon={faEthereum} style={iconStyle} />;
            case Constants.NetworkType.BSC:
                return <ReactSVG src='bnb-logo.svg' style={iconStyle} wrapper='svg' />;
        }
    }

    function getSmallerNetworkIcon() {
        const iconStyle = {height: '1.5rem', width: '1.5rem', marginRight: '1rem', flexShrink: '0'};
        switch (token.network) {
            case Constants.NetworkType.Eth:
                return <FontAwesomeIcon icon={faEthereum} style={iconStyle} />;
            case Constants.NetworkType.BSC:
                return <ReactSVG src='bnb-logo.svg' style={iconStyle} wrapper='svg' />;
        }
    }

    function getNativeCoinName() {
        switch (token.network) {
            case Constants.NetworkType.Eth:
                return Constants.NativeCoinName.Eth;
            case Constants.NetworkType.BSC:
                return Constants.NativeCoinName.BSC;
        }
    }

    function toggleIsOpen() {
        setIsOpen(!isOpen);
    }

    function getChevronIcon() {
        return isOpen ? faChevronUp : faChevronDown;
    }

    return (
        <div className="border-bottom bg-light-lm bg-dark-dm">
            <div onClick={toggleIsOpen} className="overflow-hidden d-lg-none">
                <Row>
                    <Col size={6} className="p-10 d-flex align-items-center">
                        {getSmallerNetworkIcon()}
                        <div>{token.symbol}</div>
                    </Col>
                    <Col size={6} className="p-10 text-right d-flex align-items-center justify-content-stretch">
                        <div className="d-flex align-items-center justify-content-between flex-grow-1">
                            <span>$</span>
                            <ProfitLossInlineValue value={token.usdValue} decimalsToShow={2} containerSize={18} iconSize="1.2rem" />
                        </div>
                        <FontAwesomeIcon icon={getChevronIcon()} className="flex-grow-0 ml-15" />
                    </Col>
                </Row>
                <Row className={"px-10 cpr-expanded-panel" + (isOpen ? " open" : "")}>
                    <Col size={12} className="mb-10 pt-0">
                        <div className="d-flex align-items-center justify-content-between">
                            <div>Name</div>
                            <div className="text-right">{token.name}</div>
                        </div>
                        <div className="d-flex align-items-center justify-content-between">
                            <div>Amount</div>
                            <div className="text-right text-monospaced">{toFormattedNumber(token.balance, 4)}</div>
                        </div>
                        <div className="d-flex align-items-center justify-content-between">
                            <div>{getNativeCoinName()} Value</div>
                            <div className="text-right text-monospaced">{toFormattedNumber(token.nativeCoinValue, 4)}</div>
                        </div>
                    </Col>
                    <Col size={12}>
                        <Row className="mb-5">
                            <Col size={6} className="pr-5">
                                <BlockButton bgClass="bg-success" textClass="text-white" caption="Buy" url={buyUrl} />
                            </Col>
                            <Col size={6} className="pl-5">
                                <BlockButton bgClass="bg-danger" textClass="text-white" caption="Sell" url={sellUrl} />
                            </Col>
                        </Row>
                        <Row className="mb-10">
                            <Col size={6} className="pr-5">
                                <BlockButton bgClass="bg-primary" textClass="text-white" icon={scanLogo} url={scanUrl} />
                            </Col>
                            <Col size={6} className="pl-5">
                                <BlockButton bgClass="bg-primary" textClass="text-white" icon={chartLogo} url={chartUrl} />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
            <Row className="d-none d-lg-flex">
                <Col size={3} className="p-10 bg-light-lm bg-dark-dm">{token.name}</Col>
                <Col size={3} className="p-10 bg-light-lm bg-dark-dm text-right">
                    <ProfitLossInlineValue decimalsToShow={4} value={token.balance} />
                </Col>
                <Col size={2} className="p-10 bg-light-lm bg-dark-dm text-right">
                    <ProfitLossInlineValue decimalsToShow={2} value={token.usdValue} />
                </Col>
                <Col size={1} className="p-10 bg-light-lm bg-dark-dm text-center">{getNetworkIcon()}</Col>
                <Col size={3} className="p-10 bg-light-lm bg-dark-dm d-flex align-items-center">
                    <SmallButton bgClass="bg-success" textClass="text-white" caption="B" url={buyUrl} />
                    <SmallButton bgClass="bg-danger" textClass="text-white" caption="S" url={sellUrl} />
                    <SmallButton bgClass="bg-primary" textClass="text-white" icon={scanLogo} url={scanUrl} />
                    <SmallButton bgClass="bg-primary" textClass="text-white" icon={chartLogo} url={chartUrl} />
                </Col>
            </Row>
        </div>
    )
}

export default CombinedPortfolioRow;