import { useDispatch, useSelector } from "react-redux";
import { Redirect, useParams } from "react-router";
import Button from "reacthalfmoon/dist/components/Button";
import Col from "reacthalfmoon/dist/components/Col";
import Container from "reacthalfmoon/dist/components/Container";
import Row from "reacthalfmoon/dist/components/Row";
import ProfitLossCard from "../components/ProfitLossCard";
import SinglePortfolioTable from "../components/single-portfolio/SinglePortfolioTable";
import Constants from "../constants/Constants";
import { queryBalances, queryTokens, selectBscBalances, selectEthBalances } from "../redux/slices/tokensSlice";
import toFormattedNumber from "../util/toFormattedNumber";
import useInterval from "../util/useInterval";
import Page404 from "./Page404";

function SinglePortfolio() {
    const { network } = useParams();
    const dispatch = useDispatch();
    const ethBalances = useSelector(selectEthBalances);
    const bscBalances = useSelector(selectBscBalances);
    const tokensTimer = useInterval(onLoadTokensTimerTick, 10000);

    let balances;
    let nativeCoinName;
    switch (network) {
        case Constants.NetworkType.Eth:
            balances = ethBalances;
            nativeCoinName = Constants.NativeCoinName.Eth;
            break;
        case Constants.NetworkType.BSC:
            balances = bscBalances;
            nativeCoinName = Constants.NativeCoinName.BSC;
            break;
        default:
            return <Redirect to={Page404} />;
    }

    function onLoadTokensTimerTick() {
        dispatch(queryBalances());
    }

    return (
        <>
            <h1>{network} Portfolio</h1>
            <Container breakpoint="fluid" className="mb-20">
                <Row>
                    <Col size={12} sm={6} xl={3} className="pr-sm-10 pb-10 pb-xl-0">
                        <ProfitLossCard caption="Total USD Value" value={toFormattedNumber(balances.totalUsdValue, 2)} />
                    </Col>
                    <Col size={12} sm={6} xl={3} className="pl-sm-10 pl-lg-10 pr-lg-10 pb-10 pb-xl-0">
                        <ProfitLossCard caption={"Total " + nativeCoinName + " Value"} value={toFormattedNumber(balances.totalNativeValue, 4)} />
                    </Col>
                    <Col size={12} sm={6} xl={3} className="pr-sm-10 pl-lg-10 pr-lg-10 pb-10 pb-sm-0">
                        <ProfitLossCard caption={"Native " + nativeCoinName} value={toFormattedNumber(balances.nativeAmount, 4)} />
                    </Col>
                    <Col size={12} sm={6} xl={3} className="pl-sm-10">
                        <ProfitLossCard caption={"Token Value in " + nativeCoinName} value={toFormattedNumber(balances.tokensNativeValue, 4)} />
                    </Col>
                </Row>
            </Container>
            <SinglePortfolioTable tokens={balances.tokens} nativeTokenName={nativeCoinName} />
        </>
    )
}

export default SinglePortfolio;
