import Col from 'reacthalfmoon/dist/components/Col';
import Container from 'reacthalfmoon/dist/components/Container';
import Row from 'reacthalfmoon/dist/components/Row';
import SinglePortfolioRow from './SinglePortfolioRow';

function SinglePortfolioTable({ tokens, nativeTokenName }) {
    const tokenRows = tokens.map(token => {
        if (token.usdValue < 0.005) {
            return null;
        }

        return <SinglePortfolioRow token={token} nativeTokenName={nativeTokenName} key={token.address} />
    });

    return (
        <Container breakpoint="fluid" className="border-transparent rounded overflow-hidden">
            <Row className="bg-gray-lm bg-very-dark-dm d-lg-none border-bottom">
                <Col size={6} className="p-10">Name</Col>
                <Col size={6} className="p-10 text-center">Value</Col>
            </Row>
            <Row className="bg-gray-lm bg-very-dark-dm d-none d-lg-flex border-bottom">
                <Col lg={3} className="p-10">Name</Col>
                <Col lg={3} className="p-10 text-center">Amount</Col>
                <Col lg={2} className="p-10 text-center">{nativeTokenName} Value</Col>
                <Col lg={2} className="p-10 text-center">USD Value</Col>
                <Col lg={2} className="p-10">Actions</Col>
            </Row>
            {tokenRows}
        </Container>
    )
}

export default SinglePortfolioTable;
