export default {
    NetworkType: {
        Eth: 'Ethereum',
        BSC: 'BSC',
    },
    NativeCoinName: {
        Eth: 'Eth',
        BSC: 'BNB',
    },
    ExchangeType: {
        PancakeSwapV1: 'PCSV1',
        PancakeSwapV2: 'PCSV2',
        UniswapV2: 'USV2',
    },
    CoinAddress: {
        Eth: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
        BSC: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
    },
}
