import { commify } from "@ethersproject/units";

export default function toFormattedNumber(number, decimalPlaces) {
    var fixedNumberString = '' + number.toFixed(decimalPlaces);
    var commifiedString = commify(fixedNumberString);

    var dpIndex = commifiedString.indexOf('.');
    var outputDp = commifiedString.length - dpIndex - 1;

    while (outputDp < decimalPlaces) {
        commifiedString += '0';
        dpIndex = commifiedString.indexOf('.');
        outputDp = commifiedString.length - dpIndex - 1;
    }

    return commifiedString;
}