import SidebarDivider from 'reacthalfmoon/dist/components/SidebarDivider';
import SidebarTitle from 'reacthalfmoon/dist/components/SidebarTitle';

function AppSidebarTitle({ title }) {
    return (
        <>
            <SidebarTitle style={{marginTop: '3rem', textAlign: 'center'}}>{title}</SidebarTitle>
            <SidebarDivider />
        </>
    );
}

export default AppSidebarTitle;
