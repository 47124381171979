import { faMoon, faSun } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import toggleDarkmode from "reacthalfmoon/dist/components/toggleDarkmode";
import LocalStorage from "../util/LocalStorage";

export default function DarkModeToggle() {
    const [darkmode, setDarkmode] = useState(false);
    
    useEffect(() => {
        let localStorageDarkmode = LocalStorage.LoadItem('crylio-darkmode');
        if (localStorageDarkmode !== null) {
            if (localStorageDarkmode === true) {
                setDarkmode(localStorageDarkmode);
                toggleDarkmode();
            }
        }
    }, []);

    let dividerStyle = 'px-10 py-5 text-light bg-dark-light';
    let sunContainerStyle = dividerStyle + ' rounded-left';
    let sunIconStyle = 'hover-opacity-100';
    let moonContainerStyle = dividerStyle + ' rounded-right';
    let moonIconStyle = 'hover-opacity-100';
    if (darkmode) {
        sunIconStyle += ' opacity-60';
    } else {
        moonIconStyle += ' opacity-60';
    }

    function onLightModeClick() {
        if (darkmode) {
            setDarkmode(false);
            LocalStorage.SaveItem('crylio-darkmode', false);
            toggleDarkmode();
        }
    }

    function onDarkModeClick() {
        if (!darkmode) {
            setDarkmode(true);
            LocalStorage.SaveItem('crylio-darkmode', true);
            toggleDarkmode();
        }
    }

    return (
        <div className="d-flex align-items-center">
            <div className={sunContainerStyle} onClick={onLightModeClick}><FontAwesomeIcon icon={faSun} className={sunIconStyle} /></div>
            <div className={dividerStyle}>/</div>
            <div className={moonContainerStyle} onClick={onDarkModeClick}><FontAwesomeIcon icon={faMoon} className={moonIconStyle} /></div>
        </div>
    )

}