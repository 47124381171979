import { faChartBar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Constants from "../constants/Constants";

export default function getChartInfo(tokenAddress, tokenPairAddress, networkType, iconStyle) {
    const chartLogo = <FontAwesomeIcon icon={faChartBar} style={iconStyle} />;
    let chartUrl;

    switch (networkType) {
        case Constants.NetworkType.Eth:
            chartUrl = 'https://www.dextools.io/app/uniswap/pair-explorer/' + tokenPairAddress;
            break;
        case Constants.NetworkType.BSC:
            chartUrl = 'https://poocoin.app/tokens/' + tokenAddress;
            break;
        default:
            chartUrl = '/';
            break;
    }

    return {chartLogo, chartUrl};
}