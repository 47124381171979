import { createSlice } from '@reduxjs/toolkit'
import { ethers } from "ethers";
import { queryBalances } from './tokensSlice';

export const web3Slice = createSlice({
  name: 'web3',
  initialState: {
    isConnected: false,
    isError: false,
    walletAddress: ''
  },
  reducers: {
    connected: (state, action) => {
      state.isConnected = true;
      state.walletAddress = action.payload;
    },
    errorOnConnection: state => {
      state.isConnected = false;
      state.isError = true;
    },
  }
})

export const connectWallet = () => {
    return async (dispatch, getState) => {
        try {
            await window.ethereum.enable();
            const provider = new ethers.providers.Web3Provider(window.ethereum);
            const signer = provider.getSigner();
            const walletAddress = await signer.getAddress();
            dispatch(connected(walletAddress));
            dispatch(queryBalances());
        } catch (err) {
            dispatch(errorOnConnection());
        }
    }
}

export const getConnectedWallet = () => {
    return async (dispatch, getState) => {
        try {
            const provider = new ethers.providers.Web3Provider(window.ethereum);
            const signer = provider.getSigner();
            const walletAddress = await signer.getAddress();
            dispatch(connected(walletAddress));
            dispatch(queryBalances());
        } catch (err) {
        }
    }
}

export const { connected, errorOnConnection } = web3Slice.actions

export const selectIsConnected = state => state.web3.isConnected
export const selectIsError = state => state.web3.isError
export const selectWalletAddress = state => state.web3.walletAddress

export default web3Slice.reducer