import { commify } from "@ethersproject/units";
import { useDispatch, useSelector } from "react-redux";
import Col from "reacthalfmoon/dist/components/Col";
import Container from "reacthalfmoon/dist/components/Container";
import Row from "reacthalfmoon/dist/components/Row";
import CombinedPortfolioTable from "../components/combined-portfolio/CombinedPortfolioTable";
import ProfitLossCard from "../components/ProfitLossCard";
import StatisticCard from "../components/StatisticCard";
import { queryBalances, queryTokens, selectBscBalances, selectEthBalances, selectTotalUsdValue } from "../redux/slices/tokensSlice";
import useInterval from "../util/useInterval";

function Portfolio() {
    const totalUsdValue = useSelector(selectTotalUsdValue);
    const ethBalances = useSelector(selectEthBalances);
    const bscBalances = useSelector(selectBscBalances);
    const dispatch = useDispatch();
    const tokensTimer = useInterval(onLoadTokensTimerTick, 10000);

    function onLoadTokensTimerTick() {
        dispatch(queryBalances());
    }

    const combinedTokens = ethBalances.tokens.concat(bscBalances.tokens);
    combinedTokens.sort((a, b) => b.usdValue - a.usdValue);

    return (
        <>
            <h1>Portfolio</h1>
            <Container breakpoint="fluid" className="mb-20">
                <Row>
                    <Col size={12} lg={4} className="pb-10 pb-lg-0 pr-lg-20">
                        <ProfitLossCard caption="Total USD Value" value={commify(totalUsdValue.toFixed(2))} />
                    </Col>
                    <Col size={12} lg={4} className="pb-10 pb-lg-0 pl-lg-10 pr-lg-10">
                        <ProfitLossCard caption="Ethereum USD Value" value={commify(ethBalances.totalUsdValue.toFixed(2))} />
                    </Col>
                    <Col size={12} lg={4} className="pb-10 pb-lg-0 pl-lg-20">
                        <ProfitLossCard caption="BSC USD Value" value={commify(bscBalances.totalUsdValue.toFixed(2))} />
                    </Col>
                </Row>
            </Container>
            <CombinedPortfolioTable tokens={combinedTokens} />
        </>
    )
}

export default Portfolio;
