import { faHome, faCoins } from '@fortawesome/free-solid-svg-icons';
import { faEthereum } from '@fortawesome/free-brands-svg-icons';
import Constants from './Constants';

export const MenuItems = [
    {
        type: 'link',
        path: '/',
        icon: faHome,
        name: 'Home',
    },
    {
        type: 'link',
        path: '/portfolio',
        icon: faCoins,
        name: 'Portfolio',
        requireAuth: true,
    },
    {
        type: 'title',
        name: 'Networks',
        requireAuth: true,
    },
    {
        type: 'link',
        path: '/portfolio/' + Constants.NetworkType.Eth,
        icon: faEthereum,
        name: 'Ethereum',
        requireAuth: true,
    },
    {
        type: 'link',
        path: '/portfolio/' + Constants.NetworkType.BSC,
        image: '/bnb-logo.svg',
        name: 'BSC',
        requireAuth: true,
    },
];
