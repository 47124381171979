import Constants from "../constants/Constants";

export default function getExchangeUrls(tokenAddress, exchangeType) {
    let buyUrl, sellUrl;

    switch (exchangeType) {
        case Constants.ExchangeType.PancakeSwapV1:
            buyUrl = 'https://v1exchange.pancakeswap.finance/#/swap?outputCurrency=' + tokenAddress;
            sellUrl = 'https://v1exchange.pancakeswap.finance/#/swap?inputCurrency=' + tokenAddress;
            break;
        case Constants.ExchangeType.PancakeSwapV2:
            buyUrl = 'https://exchange.pancakeswap.finance/#/swap?outputCurrency=' + tokenAddress;
            sellUrl = 'https://exchange.pancakeswap.finance/#/swap?inputCurrency=' + tokenAddress;
            break;
        case Constants.ExchangeType.UniswapV2:
            buyUrl = 'https://app.uniswap.org/#/swap?outputCurrency=' + tokenAddress;
            sellUrl = 'https://app.uniswap.org/#/swap?inputCurrency=' + tokenAddress;
            break;
        default:
            buyUrl = '/';
            sellUrl = '/';
            break;
    }

    return { buyUrl, sellUrl };
}