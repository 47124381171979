import { createSlice } from '@reduxjs/toolkit';

export const tokensSlice = createSlice({
    name: 'tokens',
    initialState: {
        totalUsdValue: 0,
        eth: {
            nativeAmount: 0,
            nativeUsdValue: 0,
            tokensNativeValue: 0,
            tokensUsdValue: 0,
            totalNativeValue: 0,
            totalUsdValue: 0,
            tokens: [],
        },
        bsc: {
            nativeAmount: 0,
            nativeUsdValue: 0,
            tokensNativeValue: 0,
            tokensUsdValue: 0,
            totalNativeValue: 0,
            totalUsdValue: 0,
            tokens: [],
        },
    },
    reducers: {
        updatedTokens: (state, action) => state = {...action.payload}
    }
})

export const queryTokens = () => {
    return async (dispatch, getState) => {
        const { web3 } = getState();
        const walletAddress = web3.walletAddress;
        const response = await fetch('https://api.cryl.io/tokens?address=' + walletAddress + '&network=all');
        const tokens = await response.json();
        const ethTokens =  tokens.filter(token => token.network === 'Ethereum');
        const bscTokens =  tokens.filter(token => token.network === 'BSC');
        const newState = {
            totalUsdValue: ethTokens.reduce((prev, current) => prev += current.usdValue, 0) + bscTokens.reduce((prev, current) => prev += current.usdValue, 0),
            eth: {
                nativeAmount: 0,
                nativeUsdValue: 0,
                tokens: ethTokens,
                tokensNativeValue: ethTokens.reduce((prev, current) => prev += current.nativeCoinValue, 0),
                tokensUsdValue: ethTokens.reduce((prev, current) => prev += current.usdValue, 0),
                totalUsdValue: ethTokens.reduce((prev, current) => prev += current.usdValue, 0),
            },
            bsc: {
                nativeAmount: 0,
                nativeUsdValue: 0,
                tokens: bscTokens,
                tokensNativeValue: bscTokens.reduce((prev, current) => prev += current.nativeCoinValue, 0),
                tokensUsdValue: bscTokens.reduce((prev, current) => prev += current.usdValue, 0),
                totalUsdValue: bscTokens.reduce((prev, current) => prev += current.usdValue, 0),
            },
        };

        dispatch(updatedTokens(newState));
    }
}

export const queryBalances = () => {
    return async (dispatch, getState) => {
        const { web3 } = getState();
        const walletAddress = web3.walletAddress;
        const response = await fetch('https://api.cryl.io/balances?address=' + walletAddress);
        const balancesObj = await response.json();
        const newState = {
            totalUsdValue: balancesObj.totalUsdValue,
            eth: {
                nativeAmount: balancesObj.ethereum.nativeAmount,
                nativeUsdValue: balancesObj.ethereum.nativeUsdValue,
                tokensNativeValue: balancesObj.ethereum.tokensNativeValue,
                tokensUsdValue: balancesObj.ethereum.tokensUsdValue,
                totalNativeValue: balancesObj.ethereum.totalNativeValue,
                totalUsdValue: balancesObj.ethereum.totalUsdValue,
                tokens: balancesObj.ethereum.tokens,
            },
            bsc: {
                nativeAmount: balancesObj.bsc.nativeAmount,
                nativeUsdValue: balancesObj.bsc.nativeUsdValue,
                tokensNativeValue: balancesObj.bsc.tokensNativeValue,
                tokensUsdValue: balancesObj.bsc.tokensUsdValue,
                totalNativeValue: balancesObj.bsc.totalNativeValue,
                totalUsdValue: balancesObj.bsc.totalUsdValue,
                tokens: balancesObj.bsc.tokens,
            },
        };
        dispatch(updatedTokens(newState));
    }
}

export const { updatedTokens } = tokensSlice.actions

export const selectEthBalances = state => state.tokens.eth
export const selectBscBalances = state => state.tokens.bsc
export const selectTotalUsdValue = state => state.tokens.totalUsdValue

export default tokensSlice.reducer