import { commify } from "@ethersproject/units";
import { useEffect, useState } from "react";
import toFormattedNumber from "../util/toFormattedNumber";
import ProfitLossIcon from "./ProfitLossIcon";

export default function ProfitLossInlineValue({ value, decimalsToShow, containerSize, iconSize }) {
    const [prevValue, setPrevValue] = useState(0);
    const [profitStatus, setProfitStatus] = useState(0);

    useEffect(() => {
        if (prevValue != 0) {
            if (value > prevValue) {
                setProfitStatus(1);
            } else if (value < prevValue) {
                setProfitStatus(-1);
            }
        }
        setPrevValue(value);
    }, [value]);

    const intContainerSize = containerSize ? containerSize : 20;
    const intIconSize = iconSize ? iconSize : "16px";

    return (
        <div className="d-flex align-items-center justify-content-end">
            <div className="text-right text-monospaced">{toFormattedNumber(value, decimalsToShow)}</div>
            <div className="flex-grow-0 ml-10">
                <ProfitLossIcon profitLossStatus={profitStatus} containerSize={intContainerSize} iconSize={intIconSize} isDarkBg={true} />
            </div>
        </div>
    );
}