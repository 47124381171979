import { NavLink, Route } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { MenuItems } from '../../constants/MenuItems';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactSVG } from 'react-svg';
import AppSidebarTitle from './AppSidebarTitle';
import Sidebar from 'reacthalfmoon/dist/components/Sidebar';
import SidebarItem from 'reacthalfmoon/dist/components/SidebarItem';
import SidebarMenu from 'reacthalfmoon/dist/components/SidebarMenu';
import { selectIsConnected, connectWallet } from '../../redux/slices/web3Slice';
import './Sidebar.css';
import Button from 'reacthalfmoon/dist/components/Button';

function AppSidebar({ toggleSidebar }) {
    const isWalletConnected = useSelector(selectIsConnected);
    const dispatch = useDispatch();


    function onConnectWalletClick() {
        dispatch(connectWallet());
        toggleSidebar();
    }

    function mapMenuItemToElement(route) {
        switch (route.type) {
            case 'link':
                return mapMenuItemToLink(route);
            case 'title':
                return mapMenuItemToTitle(route);
            default:
                return <div />;
        }
    }

    function mapMenuItemToLink(route) {
        let icon;

        if (route.requireAuth) {
            if (!isWalletConnected) {
                return null;
            }
        }

        if (route.image) {
            icon = <ReactSVG src={route.image} style={{height: '1em'}} wrapper='svg' />;
        } else {
            icon = <FontAwesomeIcon icon={route.icon} />;
        }

        return (
            <NavLink
                exact
                to={route.path}
                className="sidebar-link d-flex align-items-center"
                key={route.path}
                onClick={toggleSidebar}>
                <Route path={route.path} exact={route.exact} />
                <span className="sidebar-icon d-inline-flex">
                    {icon}
                </span>
                {route.name}
            </NavLink>
        );
    }

    function mapMenuItemToTitle(route) {
        if (route.requireAuth) {
            if (!isWalletConnected) {
                return null;
            }
        }

        return <AppSidebarTitle title={route.name} key={route.name} />;
    }

    let sidebarItems = MenuItems.map(mapMenuItemToElement);
    
    return (
        <Sidebar className="left-md-0 bg-light-lm crylio-sidebar">
            <SidebarMenu>
                {sidebarItems}
            </SidebarMenu>
            {(!isWalletConnected) ? 
                (<SidebarMenu>
                    <SidebarItem>
                        <Button color="primary" block onClick={onConnectWalletClick}>Connect Wallet</Button>
                    </SidebarItem>
                </SidebarMenu>)
                : null}
        </Sidebar>
    );
}

export default AppSidebar;
