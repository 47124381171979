import { faArrowDown, faArrowUp, faMinus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function ProfitLossIcon({ profitLossStatus, containerSize, iconSize, isDarkBg }) {

    let icon = faMinus;
    let colourClasses;
    if (isDarkBg) {
        colourClasses = "bg-gray-lm bg-dark-light-dm";
    } else {
        colourClasses = "bg-gray-light-lm bg-dark-dm";
    }
    if (profitLossStatus > 0) {
        icon = faArrowUp;
        colourClasses = "bg-success text-white";
    } else if (profitLossStatus < 0) {
        icon = faArrowDown;
        colourClasses = "bg-danger text-white";
    }

    let actualContainerSize = containerSize ? containerSize : "40";
    let actualIconSize = iconSize ? iconSize : "40%";
    let classes = colourClasses + " rounded-circle border-transparent overflow-hidden w-" + actualContainerSize + " h-" + actualContainerSize + " d-flex align-items-center justify-content-center";

    return (
        <div className={classes}>
            <FontAwesomeIcon icon={icon} style={{width: actualIconSize, height: actualIconSize}} />
        </div>
    )

}