import { useState } from 'react';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DarkmodeSwitch from 'reacthalfmoon/dist/components/DarkmodeSwitch';
import Navbar from 'reacthalfmoon/dist/components/Navbar';
import NavbarBrand from 'reacthalfmoon/dist/components/NavbarBrand';
import NavbarContent from 'reacthalfmoon/dist/components/NavbarContent';
import { ReactComponent as DarkLogo } from '../../img/dark-logo.svg';
import './Header.css';
import DarkModeToggle from '../DarkmodeToggle';

function Header({ onToggleClick }) {
    const [darkmode, setDarkmode] = useState(false);
    return (
        <Navbar className="crylio-header">
            <NavbarContent className="d-md-none">
                <button className="crylio-header__toggle" type="button" onClick={onToggleClick}>
                    <FontAwesomeIcon icon={faBars} className="ml-10 mr-10" />
                    <span className="sr-only">Toggle sidebar</span>
                </button>
            </NavbarContent>
            <NavbarBrand className="d-inline-flex h-full ml-md-5 mr-auto" href="/">
                <DarkLogo className="h-full w-auto crylio-header__logo" />
            </NavbarBrand>
            <NavbarContent className="ml-auto">
                <DarkModeToggle />
            </NavbarContent>
        </Navbar>
    );
}

export default Header;
