import Col from 'reacthalfmoon/dist/components/Col';
import Container from 'reacthalfmoon/dist/components/Container';
import Row from 'reacthalfmoon/dist/components/Row';
import CombinedPortfolioRow from './CombinedPortfolioRow';

function CombinedPortfolioTable({ tokens }) {
    const tokenRows = tokens.map(token => {
        if (token.usdValue < 0.005) {
            return null;
        }

        return <CombinedPortfolioRow token={token} key={token.address} />
    });

    return (
        <Container breakpoint="fluid" className="border-transparent rounded overflow-hidden">
            <Row className="bg-gray-lm bg-very-dark-dm d-lg-none">
                <Col size={6} className="p-10 pl-35">Name</Col>
                <Col size={6} className="p-10 text-center">Value</Col>
            </Row>
            <Row className="bg-gray-lm bg-very-dark-dm d-none d-lg-flex">
                <Col size={3} className="p-10">Name</Col>
                <Col size={3} className="p-10 text-center">Amount</Col>
                <Col size={2} className="p-10 text-center">USD Value</Col>
                <Col size={1} className="p-10 text-center">Network</Col>
                <Col size={3} className="p-10">Actions</Col>
            </Row>
            {tokenRows}
        </Container>
    )
}

export default CombinedPortfolioTable;
