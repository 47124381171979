import Home from '../pages/Home';
import Portfolio from '../pages/Portfolio';
import SinglePortfolio from '../pages/SinglePortfolio';

export const AppRoutes = [
    {
        path: '/',
        component: Home,
    },
    {
        path: '/portfolio',
        component: Portfolio,
        requireAuth: true,
    },
    {
        path: '/portfolio/:network',
        component: SinglePortfolio,
        requireAuth: true,
    },
];
